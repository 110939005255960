<template>
  <b-card>
    <div class="custom-container">
      <div
        v-if="infoCurso !== null"
        class="banner-info position-relative rounded"
      >
        <div
          class="p-3 d-flex flex-column content-banner"
          style="width: 60%; gap: 10px"
        >
          <div>
            <vs-button
              size="large"
              transparent
              class="m-0"
              to="/helex/Ayuda/Academia/"
            >
              <i class="fas fa-chevron-left mr-1"></i> Volver a cursos
            </vs-button>
          </div>
          <h1 class="font-weight-bold text-capitalize">
            {{ infoCurso.titulo }}
          </h1>
          <p class="h4 text-white-50" style="line-height: 1.5">
            {{ infoCurso.descripcion }}
          </p>
          <div>
            <!-- <div class="d-flex align-items-center flex-wrap">
              <b-form-rating
                id="rating-inline"
                variant="warning"
                readonly
                inline
                show-value
                no-border
                value="4.9"
                class="bg-transparent p-0"
                style="font-size: 1rem"
              ></b-form-rating>
              <span
                class="custom-punto d-flex align-items-center"
                style="font-size: 1rem"
                >(120 calificaciones)</span
              >
              <vs-tooltip>
                <i
                  :class="`fas fa-${
                    infoCurso.privacidad === 1 ? 'earth-americas' : 'lock'
                  }`"
                ></i>
                <template #tooltip>{{
                  infoCurso.privacidad === 1 ? "Curso público" : "Curso privado"
                }}</template>
              </vs-tooltip>
            </div> -->
            <div class="d-flex my-2">
              <span
                class="custom-punto d-flex align-items-center text-success font-weight-bold"
                >{{ infoCurso.categoriaCurso?.descripcion }}</span
              >
              <vs-tooltip>
                <i
                  :class="`fas fa-${
                    infoCurso.privacidad === 1 ? 'earth-americas' : 'lock'
                  }`"
                ></i>
                <template #tooltip>{{
                  infoCurso.privacidad === 1 ? "Curso público" : "Curso privado"
                }}</template>
              </vs-tooltip>
            </div>
            <div class="d-flex flex-wrap">
              <span class="custom-punto d-flex align-items-center">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1">
                  {{
                    infoCurso.ultimaActualizacion | formatLastUpdateDate
                  }}</span
                >
              </span>
              <span class="text-capitalize"
                >Por {{ infoCurso.responsable.nombres }}
                {{ infoCurso.responsable.apellidos }}</span
              >
            </div>
          </div>
        </div>
        <div
          class="position-absolute custom-card"
          style="top: 20%; right: 5%; z-index: 2"
        >
          <b-card
            title="Este curso incluye:"
            :img-src="infoCurso.previewImagenUrl ?? '/img/image_cursos_alt.png'"
            :img-alt="infoCurso.titulo"
            img-top
            tag="article"
            style="max-width: 25rem"
            class="mb-2"
            bg-variant="dark"
          >
            <b-card-text>
              <div class="d-flex align-items-center" style="gap: 10px">
                <div
                  style="width: 40px; height: 40px"
                  class="border rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i class="fas fa-clock"></i>
                </div>
                <span
                  >{{
                    listaClases.reduce(
                      (totalHoras, clase) =>
                        totalHoras + parseInt(clase.duracion),
                      0
                    )
                  }}
                  horas de vídeo bajo demanda</span
                >
              </div>
              <div
                v-if="infoCurso.certificadoFinalizacion === 1"
                class="d-flex align-items-center mt-2"
                style="gap: 10px"
              >
                <div
                  style="width: 40px; height: 40px"
                  class="border rounded-circle d-flex align-items-center justify-content-center"
                >
                  <i class="fas fa-trophy"></i>
                </div>
                <span>Certificado de finalización</span>
              </div>
            </b-card-text>
            <vs-button
              :to="`/helex/Ayuda/Academia/AcademiaVideos/${infoCurso.id}`"
              animation-type="vertical"
              block
              danger
              
              size="large"
            >
              Iniciar ahora
              <template #animate> <i class="fas fa-play mr-2"></i></template>
            </vs-button>
          </b-card>
        </div>
      </div>
      <div
        v-if="listaClases.length > 0"
        class="p-3 content-curso"
        style="width: 60%"
      >
        <h3>Contenido del curso</h3>
        <div class="d-flex my-2">
          <span class="custom-punto d-flex align-items-center">
            {{ listaClases.length }}
            {{ listaClases.length > 1 ? "clases" : "clase" }}
          </span>
          <span
            >{{
              listaClases.reduce(
                (totalHoras, clase) => totalHoras + parseInt(clase.duracion),
                0
              )
            }}h duración total</span
          >
        </div>
        <div class="content-list-class">
          <b-list-group class="mt-2 custom-content list-clases">
            <b-list-group-item
              v-for="clase in listaClases"
              :key="clase.id"
              class="d-flex align-items-center justify-content-between item-list rounded py-4 border-0"
              style="background: #0f213f"
            >
              <div class="d-flex align-items-center" style="width: 90%">
                <i class="fas fa-circle-play mr-2" style="font-size: 2rem"></i>
                <div>
                  <h4
                    class="custom-text-truncate-title h4 m-0 text-capitalize font-weight-bold"
                    style="max-width: 100%"
                  >
                    {{ clase.titulo }}
                  </h4>
                  <p class="custom-text-truncate-title m-0 text-white-50">
                    {{ clase.descripcion }}
                  </p>
                </div>
              </div>
              <span class="font-weight-bold text-white-50">
                {{ clase.duracion }}h
              </span>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
      <div
        v-else
        class="d-flex justify-content-center align-items-center font-italic h3"
      >
        <i class="fas fa-spinner fa-spin mr-1"></i>
        Cargando datos...
      </div>
    </div>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";

export default {
  name: "infoCurso",
  data() {
    return {
      infoCurso: {
        id: null,
        titulo: "",
        descripcion: "",
        previewImagenUrl: null,
        privacidad: null,
        responsableId: null,
        responsable: {},
        certificadoFinalizacion: null,
        categoriaCurso: {},
        clases: [],
        categoriaCursoId: null,
        ultimaActualizacion: null,
      },
      listaClases: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          duracion: "",
          urlClaseVideo: "",
          crusoAcademiaId: null,
          ultimaAcatualizacion: "",
          responsableId: null,
          responsable: {},
          cursoAcademia: {},
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.infoCurso = null;
    this.listaClases = [];
    await this.getListClasesCursoAcademia(this.$route.params.id);
    this.$isLoading(false);
  },
  methods: {
    async getListClasesCursoAcademia(cursoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ClasesCursosAcademia/All/${cursoId}`,
        });
        console.log("list clases cursoAcademia...", res);
        if (res.success) {
          this.infoCurso = res.data[0].cursoAcademia;
          this.listaClases = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {
    formatLastUpdateDate(fechaOriginal) {
      return moment_timezone
        .tz(fechaOriginal, "America/Bogota")
        .format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>
.custom-content {
  max-height: 400px;
  overflow: auto;
}

.content-list-class {
  position: relative;
  max-height: 400px;
  overflow: hidden;
}
.list-clases {
  max-height: 400px;
  overflow-y: scroll;
  gap: 0.5rem;
  padding-bottom: 200px;
  scroll-behavior: smooth;
}

.content-list-class::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; /* Altura del área de desvanecimiento */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(16, 36, 68));
  pointer-events: none; /* Evita que el degradado interfiera con la interacción del usuario */
}

@keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }
  to {
    opacity: 1;
    scale: 100%;
  }
}

.item-list {
  view-timeline-name: --clase;
  view-timeline-axis: block;

  animation-timeline: --clase;
  animation-name: show;
  animation-range: entry 25% cover 50%;
  animation-fill-mode: both;
}

.custom-content::-webkit-scrollbar-thumb {
  background: rgba(250, 250, 250, 0.1);
}
/* .banner-info {
  background-image: url(/img/blob-scene-haikei-l.svg);
  background-size: cover;
  background-position: center;
}
.dark .banner-info {
  background-image: url(/img/blob-scene-haikei.svg);
  background-size: cover;
  background-position: center;
} */


@media (max-width: 1200px) {
  .custom-card {
    position: static !important;
    display: flex;
    justify-content: center;
  }
  .content-banner,
  .content-curso {
    width: 100% !important;
  }
}

.custom-text-truncate-p {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.custom-text-truncate-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
</style>
